<template>
  <div style="background: #f2f2f3">
    <van-nav-bar title="提现" left-arrow @click-left="onClickLeft" />
    <van-notice-bar
      style="height: 29px"
      color="#FD4E4E"
      background="#F4E6E6"
      text="建议微信提现，现金秒到账"
    >
      <template #left-icon>
        <img
          style="width: 13px; height: 11px; margin-right: 9px"
          src="https://t.yhaocang.com/upload/sf_static/img/withdrawal/laba.png"
          alt=""
        />
      </template>
    </van-notice-bar>
    <div class="content">
      <div class="pay" v-if="bankCardList.length <= 0">
        <div class="pay-title" @click="showPopup">去添加银行卡</div>
        <div class="pay-icon">+</div>
      </div>
      <van-cell v-else is-link @click="showSelectCard = true">
        <template #title>
          <div class="withdraw-title">
            提现至:{{ bankCardList[selectCard].bank_name }}
          </div>
        </template>
      </van-cell>
      <div class="payInput">
        <div class="payInput-title">提现金额</div>
        <div class="payInput-content">
          <van-field
            clearable
            label-width="10px"
            v-model="money"
            placeholder="请选择需要提现的金额"
            type="number"
            @input="moneyInput"
          >
            <div slot="label" class="label">￥</div>
          </van-field>
          <div></div>
        </div>
        <!-- 电话验证 -->
        <!-- 电话验证 -->
        <div class="payInput-info">可提现余额￥{{ left_profit }}</div>
        <div class="payInput-btn" @click="haelChange">
          <div>立即提现</div>
        </div>
      </div>
    </div>
    <div class="record">
      <span
        @click="
          $router.push({
            path: '/recordDetails/withdrawalsRecord',
            query: { type: 1 }
          })
        "
        >提现记录</span
      >
      <span class="interval">|</span>
      <span
        @click="
          $router.push({
            path: '/recordDetails/incomeBreakdown',
            query: { type: 0 }
          })
        "
        >收入明细</span
      >
    </div>
    <div class="txt">
      <p class="title">提现说明</p>
      <p class="title2">1、账户中的钱，什么时候到账?</p>
      <p class="title3">
        ①提现打款周期:工作日当日12:00之前提现的金额，1-7个工作日内安排打款，工作日当日12:00之后提现的金额，次日开始，1-7个工作日安排打款。节假日顺延。<br />
        ②到账时间:打款后1-2小时立即到账。<br />
        以上遇节假日和周六日顺延如有疑问请觅网科技。<br />
        联系电话：027-87001446
      </p>
      <p class="title2">2、提现有什么要求吗?</p>
      <p class="title3" style="padding-bottom: 100px">
        ①提现设置要求:请确保提现银行卡号跟用户名一致！否则审核失败。<br />
        ②在“数据中心”中，点“(提现)”，输入提现金额，点击“立即提现”，输入手机验证码验证通过后，即可提交提现申请。<br />
        ③提现申请提交后，可以在“提现记录”中查看提现记录。<br />
      </p>
    </div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="popupBox">
        <div class="payment">
          <div class="paymentTitle">填写银行卡信息</div>
          <div class="pay">
            <van-form @submit="onSubmit">
              <van-field
                v-model="form.bank_card_number"
                @input="cardNumberChange"
                maxlength="19"
                type="number"
                name="银行卡号"
                label="银行卡号"
                placeholder="请填写银行卡号"
                :rules="[
                  { required: true },
                  {
                    pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{17}|\d{18}|\d{19})$/,
                    message: '银行卡格式错误！'
                  }
                ]"
              />
              <van-field
                v-model="form.bank_name"
                type="txt"
                name="开户银行"
                label="开户银行"
                placeholder="请填写银行名称"
                :rules="[{ required: true }]"
              />
              <van-field
                v-model="form.cardholder"
                type="txt"
                name="持卡人"
                label="持卡人"
                placeholder="请输入持卡人姓名"
                :rules="[{ required: true }]"
              />
              <van-field
                v-model="form.mobile"
                type="number"
                name="手机号"
                label="手机号"
                placeholder="请输入手机号"
                :rules="[
                  { required: true },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '手机号码格式错误！'
                  }
                ]"
              />
              <van-field
                v-model="form.keyCode"
                center
                clearable
                type="number"
                name="短信验证码"
                label="短信验证码"
                placeholder="请输入短信验证码"
              >
                <template #button>
                  <div @click="getCode" v-show="codeShow">获取验证码</div>
                  <div v-show="!codeShow">{{ yzmTitle }}</div>
                </template>
              </van-field>
              <div style="margin: 16px">
                <van-button
                  style="background-color: #2e2d33; border: 0"
                  round
                  block
                  type="info"
                  native-type="submit"
                  >提交</van-button
                >
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showSelectCard"
      position="bottom"
      closeable
      :style="{ height: '50%' }"
    >
      <div class="select-card-pop">
        <van-radio-group v-model="selectCard" @change="showSelectCard = false">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in bankCardList"
              :key="index"
              :title="item.bank_name + `${item.bank_card_number || ''}`"
              clickable
              @click="radio = '1'"
            >
              <template #right-icon>
                <van-radio :name="index" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-cell title="使用新银行卡" @click="show = true"></van-cell>
      </div>
    </van-popup>

    <!-- 提现弹出框 -->
    <van-popup
      class="vanStr"
      v-model="strList"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="payment_List">
        <div class="payment_1">选择提现方式</div>
        <!-- 支付方式 -->
        <van-radio-group v-model="selectCard" @change="showSelectCard = false">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in bankCardList"
              :key="index"
              :title="item.bank_name + `${item.bank_card_number || ''}`"
              clickable
              @click="radio = '1'"
            >
              <template #right-icon>
                <van-radio :name="index" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-cell title="使用新银行卡" @click="show = true"></van-cell>
        <div class="payInput-btn">
          <div @click="btnClick">确定</div>
        </div>
      </div>
    </van-popup>
    <!-- 获取容器 -->
    <van-popup v-model="payInputTan" class="payment_Chu" get-container="body">
      <div class="payment_2">
        <div class="payment_3">
          <div class="payment_4">提现{{ money }}元</div>
          <!-- 电话表单 -->
          <van-form @submit="onSubmit">
            <van-field v-model="phone" placeholder="验证手机号" disabled />
            <van-field v-model="Yzm" type="text" placeholder="验证码">
              <template #right-icon>
                <div
                  v-show="codeShowZ"
                  class="VerificationCode"
                  @click="LoginFrom"
                >
                  获取验证码
                </div>
                <div v-show="!codeShowZ" class="VerificationCode">
                  {{ yzmTitleZ }}
                </div>
              </template>
            </van-field>
            <div class="payInput-btn">
              <div @click="toMoney()">确定</div>
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import { cashMoney } from "@/api/recordDetails";
import {
  getNetoCode,
  addBand,
  verifyCardNumber,
  getBankList,
  getBalance,
  withdraw
} from "@/api/withdrawal";
import { getOpenId } from "@/api/login";
import { getCodeList } from "@/api/login";
export default {
  data() {
    return {
      money: "",
      show: false,
      payShow: false,
      in_wechat: this.$store.state.isWeiXin,
      strList: false,
      payInputTan: false,
      phone: "",
      Yzm: "",
      codeShowZ: true,
      yzmTitleZ: "",
      list: [
        {
          name: "支付宝",
          img:
            "https://t.yhaocang.com/upload/sf_static/img/purchaseCard/ali.png",
          checked: true
        },
        {
          name: "微信支付",
          img:
            "https://t.yhaocang.com/upload/sf_static/img/purchaseCard/wechat.png",
          checked: false
        }
      ],
      left_profit: "",
      bank_id: "",
      selectCard: 0,
      bankCardList: [],
      showSelectCard: false, //选择银行卡弹框
      yzmTitle: "",
      codeShow: true,
      form: {
        bank_card_number: "",
        bank_name: "",
        cardholder: "",
        mobile: "",
        keyCode: ""
      }
    };
  },
  watch: {
    payInputTan: function(newVal) {
      if (!newVal) {
        // this.phone = "";
        this.Yzm = "";
      }
    },
    immediate: true
  },
  created() {
    if (this.$store.state.isWeiXin) {
      this.bankCardList.push({
        bank_name: "提现到微信",
        pay_type: 1
      });
    }
    if (this.$route.query.code) {
      getOpenId({
        in_wechat: this.in_wechat,
        // returnUrl: "https://f.yhaocang.com"
        code: this.$route.query.code || "",
        returnUrl: window.location.href
      }).then(res => {
        console.log(res, window.location.href);
        if (res.data.code == 302) {
          // console.log(res.data.data.url, "微信回调");
          window.location.href = res.data.data.url;
        }
        this.$toast(res.data.message);
      });
    }
    getBankList().then(res => {
      console.log(...res.data.data);
      this.bankCardList.push(...res.data.data);
    });
    getBalance().then(res => {
      console.log(res, "获取金额");
      this.left_profit = res.data.data.left_profit;
    });
  },
  methods: {
    btnClick() {
      this.payInputTan = true;
      getBalance(this.phone).then(res => {
        this.phone = res.data.data.mobile;
      });
      if (this.payInputTan) {
        this.strList = false;
      }
    },
    haelChange() {
      if (!this.money || this.money == 0) {
        this.$toast("金额不能为0");
        return;
      }
      if (this.money > this.total_profit) {
        this.$toast("提现金额不能大于可取现余额");
        return;
      }
      this.strList = true;
    },
    // 手机号校验
    LoginFrom() {
      // alert("11");
      let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!phonereg.test(this.phone)) {
        this.$toast("手机号格式不正确");
        return;
      }
      if (!this.phone) {
        this.$toast("请输入手机号码");
        return;
      }
      let params = {
        mobile: this.phone
      };
      getCodeList(params).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          let time = 60;
          let timer = setInterval(() => {
            if (time == 0) {
              clearInterval(timer);
              this.codeShowZ = true;
            } else {
              this.codeShowZ = false;
              this.yzmTitleZ = time + "秒后重试";
              time--;
            }
          }, 1000);
          this.$toast("验证码获取成功");
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    moneyInput() {
      console.log(this.money);
      // this.money = Number(this.money).toFixed(2);
      if (this.money * 1 > this.left_profit * 1) {
        this.money = this.left_profit;
      }
    },
    cardNumberChange(e) {
      if (e.length > 6) {
        verifyCardNumber(e).then(res => {
          this.form.bank_name = res.data.data.bank_name;
        });
      }
    },
    onClickLeft() {
      this.$router.push("/data");
    },
    //弹出层
    showPopup() {
      this.show = true;
    },
    //选择支付方式
    radioGroupChange(e) {
      this.payContentChange = e;
    },
    toMoney() {
      let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!phonereg.test(this.phone)) {
        this.$toast("手机号格式不正确");
        return;
      }
      if (!this.phone || !this.Yzm) {
        this.$toast("请输入验证码");
        return;
      }
      withdraw({
        money: this.money,
        bank_id: this.bankCardList[this.selectCard].id || "",
        pay_type: this.bankCardList[this.selectCard].pay_type || 2,
        mobile: this.phone,
        keyCode: this.Yzm
      }).then(res => {
        if (res.data.code == 601) {
          getOpenId({
            in_wechat: this.in_wechat,
            // returnUrl: "https://f.yhaocang.com"
            code: this.$route.query.code || "",
            returnUrl: window.location.href
          }).then(res => {
            console.log(res, window.location.href);
            if (res.data.code == 302) {
              console.log(res.data.data.url, "微信回调");
              window.location.href = res.data.data.url;
            }
            this.$toast(res.data.message);
          });
          this.$toast(res.data.message);
        }
        if (res.data.code == 200) {
          this.$toast("提现成功");
          console.log(res.data.data.pay_type);
          if (res.data.data.pay_type == 1) {
            console.log(res.data.data.pay_type);
            this.$router.push("/succeed");
          } else if (res.data.data.pay_type == 2) {
            console.log(res.data.data.pay_type);
            this.$router.push("/audit");
          }
          this.strList = false;
          this.payInputTan = false;
          this.money = "";
          // this.phone = "";
          this.yzm = "";
          getBalance().then(res => {
            this.left_profit = res.data.data.left_profit;
          });
        } else {
          if (res.data.message == !216) {
            this.$router.push({
              path: "/loser",
              query: { message: res.data.message }
            });
          }
          this.$toast(res.data.message);
        }
      });
    },
    // 银行卡接口
    onSubmit() {
      addBand(this.form).then(res => {
        if (res.data.code == 200) {
          this.$toast("绑定成功");
          this.show = false;
          getBankList().then(res => {
            this.bankCardList = res.data.data;
          });
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    // 获取验证码接口
    getCode() {
      if (this.form.mobile.length <= 0) {
        this.$toast("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.form.mobile)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      getNetoCode({ mobile: this.form.mobile, type: 1 }).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          let time = 60;
          let timer = setInterval(() => {
            if (time == 0) {
              clearInterval(timer);
              this.codeShow = true;
            } else {
              this.codeShow = false;
              this.yzmTitle = time + "秒后重试";
              time--;
            }
          }, 1000);
          this.$toast(res.data.message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-icon-arrow-left {
  color: #000;
}
.vanStr {
  border-radius: 14px 14px 0px 0px;
  .payment_List {
    width: 100%;
    height: 100%;
    padding: 20px;
    .payment_1 {
      text-align: center;
      font-size: 38px;
      font-weight: 600;
    }
  }
  .payInput-btn {
    margin: 50px 42px 0px 42px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 83px;
      background: #343230;
      border-radius: 42px;
      font-size: 35px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.payment_Chu {
  border-radius: 20px;
}
.payment_2 {
  width: 550px;
  height: 550px;
  .payment_3 {
    padding: 48px;
    .payment_4 {
      text-align: center;
      font-size: 50px;
      font-weight: 600;
    }
    .van-form {
      margin-top: 50px;
    }
    .VerificationCode {
      width: 150px;
      height: 40px;
      border: 1px solid #f64514;
      border-radius: 26px;
      line-height: 40px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #f64514;
      text-align: center;
    }
  }
  .payInput-btn {
    margin: 50px 42px 0px 42px;
    height: 50px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 70px;
      background: #343230;
      border-radius: 42px;
      font-size: 35px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.content {
  margin-left: 21px;
  margin-right: 21px;
  background: #ffffff;
  .pay {
    width: 100%;
    height: 111px;
    margin-top: 30px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .pay-title {
      font-size: 31px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #dfc89a;
    }
    .pay-icon {
      border: 1px solid #dfc89a;
      color: #dfc89a;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      margin-left: 13px;
    }
  }
  .withdraw-title {
    font-size: 28px;
    font-weight: 600;
    color: #000;
  }
  .payInput {
    height: 444px;
    width: 100%;
    box-shadow: 2px -4px 6px 0px rgba(167, 167, 167, 0.11);
    border-radius: 14px;
    .payInput-title {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      margin-left: 33px;
      padding-top: 48px;
    }
    .payInput-btn {
      margin: 50px 42px 0px 42px;
      height: 50px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;
        background: #343230;
        border-radius: 42px;
        font-size: 35px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .label {
      // line-height: 56px;
      margin-top: 20px;
      font-weight: bold;
    }
    /deep/.van-field__control {
      font-size: 56px;
      font-weight: bold;
      color: #000000;
    }
    /deep/.van-field__control::placeholder {
      font-size: 28px;
    }
    .payInput-info {
      margin-left: 39px;
      margin-top: 35px;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #747474;
    }
  }
}
.record {
  margin-top: 65px;
  display: flex;
  justify-content: center;

  font-size: 31px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #dfc89a;
  .interval {
    margin: 0 40px;
  }
}
.txt {
  margin-left: 21px;
  margin-right: 21px;
  margin-top: 150px;
  .title {
    font-size: 31px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
  }
  .title2 {
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #626262;
    margin-top: 22px;
  }
  .title3 {
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #626262;
    margin-top: 22px;
    line-height: 40px;
  }
}
.popupBox {
  .popupPrice {
    text-align: center;
    padding-top: 58px;
    .popupPriceRmb {
      font-size: 76px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 1;
      .rmb {
        font-size: 56px;
      }
    }
    .popupPriceInfo {
      padding-top: 30px;
      font-size: 31px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #878787;
      line-height: 1;
    }
  }
  .payment {
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 53px;
    .paymentTitle {
      text-align: center;
      font-size: 35px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
    }
    .pay {
      margin-top: 39px;
      .payBox {
        display: flex;
        align-items: center;
        padding-top: 34px;
        .payName {
          font-size: 31px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #848484;
          margin-left: 20px;
        }
      }
    }
    .submitBtn {
      position: absolute;
      bottom: 36px;
      width: 665px;
      height: 90px;
      background: #343230;
      border-radius: 45px;
      text-align: center;
      .submitBtnTxt {
        color: #ffffff;
        font-size: 34px;
        line-height: 90px;
      }
    }
  }
}
.check {
  width: 40px;
  height: 40px;
  margin-left: auto;
}
.select-card-pop {
  margin-top: 100px;
}
</style>
