import { axios } from "./../plugins/axios";

/**
 * @method 获取短信验证码
 */
export function getNetoCode(parameter) {
  return axios.request("", {
    method: "post",
    params: {
      module: "fans",
      action: "login",
      app: "secret_key",
      ...parameter
    }
  });
}

/**
 * @method 添加银行卡号
 */
export function addBand(parameter) {
  return axios.request({
    method: "post",
    params: {
      module: "fans",
      action: "withdraw",
      app: "addBand",
      ...parameter
    }
  });
}
/**
 * @method 验证银行卡号与银行名是否匹配
 * @param {Number} bank_card_number 银行卡号
 * @returns {Promise}
 */
export function verifyCardNumber(bank_card_number) {
  return axios.request({
    params: {
      module: "fans",
      action: "withdraw",
      app: "verification",
      bank_card_number
    }
  });
}
/**
 * @method 获取添加的银行卡列表
 * @returns
 */
export function getBankList() {
  return axios.request({
    params: {
      module: "fans",
      action: "withdraw",
      app: "get_bank_list"
    }
  });
}
/**
 * @method 查询提现余额
 */
export function getBalance() {
  return axios.request({
    params: {
      module: "fans",
      action: "profit",
      app: "get_drawal_money"
    }
  });
}
/**
 * @method 提现
 * @param {Object} parameter money 提现金额 bank_id 绑定银行卡id
 * @returns
 */
export function withdraw(parameter) {
  return axios.request({
    method: "post",
    params: {
      module: "fans",
      action: "withdraw",
      app: "withdrawal",
      ...parameter
    }
  });
}
